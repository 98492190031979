<template>
  <div class="shop-settings">
    <el-dialog :title="$t('Website Content Updated')" :visible.sync="displaySuccess" width="60%">
      {{ $t('Update Successed !') }}
    </el-dialog>
    <div class="page-header">
      <h1>{{ $t('Settings') }} > {{ $t('Website Settings') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Website Settings') }}</h2>
      </div>
      <div class="form-content">
        <el-form ref="form" :model="form">
          <el-form-item :label="$t('Website Name')" style="max-width: 400px">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Website Desciption (Chinese)')" style="max-width: 400px">
            <el-input v-model="form.descZh" type="textarea" :rows="5"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Website Desciption (English)')" style="max-width: 400px">
            <el-input v-model="form.descEn" type="textarea" :rows="5"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Company Contact')" style="max-width: 400px">
            <el-input v-model="form.contact"></el-input>
          </el-form-item>

          <el-form-item class="update-button">
            <el-button @click="updateSettings" type="primary">{{ $t('Update') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';

export default {
  name: 'WebsiteSettings',
  mounted(){
    this.loadSettings();
  },
  data(){
    const form = {
      name: 'Joyful Clothes',
      descZh: '',
      descEn: '',
      contact: '',
    };
    return {
      form,
      displaySuccess: false,
    }
  },
  methods:{
    async loadSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        let websiteName = await Common.loadConfig(this.apiUrl, 'website_name', loginInfo)
        websiteName = websiteName['config_value'];

        let websiteDescZh = await Common.loadConfig(this.apiUrl, 'website_introduce_en', loginInfo);
        websiteDescZh = websiteDescZh['config_value'];

        let websiteDescEn = await Common.loadConfig(this.apiUrl, 'website_introduce_zh', loginInfo);
        websiteDescEn = websiteDescEn['config_value'];

        let contact = await Common.loadConfig(this.apiUrl, 'company_contact', loginInfo);
        contact = contact['config_value'];

        this.form.name = websiteName;
        this.form.descZh = websiteDescZh;
        this.form.descEn = websiteDescEn;
        this.form.contact = contact;

      }catch(err){
        console.log(err);
      }
    },
    async updateSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        const websiteName = this.form.name;
        const websiteDescZh = this.form.descZh;
        const websiteDescEn = this.form.descEn;
        const contact = this.form.contact;

        await Common.updateConfig(this.apiUrl, 'website_name', websiteName, loginInfo);
        await Common.updateConfig(this.apiUrl, 'website_introduce_en', websiteDescZh, loginInfo);
        await Common.updateConfig(this.apiUrl, 'website_introduce_zh', websiteDescEn, loginInfo);
        await Common.updateConfig(this.apiUrl, 'company_contact', contact, loginInfo);

        this.displaySuccess = true;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.shop-settings{
  .form-content{
    padding-left: 20px;
    .shop-image-wrapper{
      display: flex;
      .shop-logo{
        margin-right: 50px;
      }
    }
    .el-form-item__label{
      float: none;
    }
    .update-button{
      .el-form-item__content{
        text-align: right;
      }
    }
  }
}
</style>
